import React, { useState } from 'react';
import './FAQ.css';
import faqData from './faq.json';

function FAQ() {
  const [visible, setVisible] = useState({});

  const toggleAnswer = (index) => {
    setVisible(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <div class="FAQ-wrapper">
      <div className='FAQ-container'>
        <h2>Perguntas Frequentes</h2>
        {faqData.map((faq, index) => (
          <div key={index} className='FAQ-item'>

            <button className='FAQ-question' onClick={() => toggleAnswer(index)}>
              <div>{faq.question}</div> <div>{visible[index] ? '-' : '+'}</div>
            </button>

            <div className={`FAQ-answer ${visible[index] ? 'visible' : ''}`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
