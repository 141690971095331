import React from "react";
import "./Servicos.css";
import Logo from '../../assets/imgs/T (1).svg';
import especialidadesData from './especialidades.json';

function Especialidades() {
  return (
    <div class="especialidades-wrapper">
      <div className="especialidades-container">
        <div className="header">
          <img src={Logo} alt="logo"></img>
          <h2>Conheça as nossas especialidades</h2>
        </div>
        <div className="servicos-grid">
          {especialidadesData.map((item, index) => (
            <div key={index}>
              <div>
                <i className="uil uil-balance-scale"></i>
                <h3>{item.titulo}</h3>
              </div>
              <p>{item.texto}</p>
              <a
                href="https://wa.me/5584999223185"
                target="_blank"
                rel="noreferrer"
              >
                <i class="uil uil-angle-double-right"></i> Fale conosco
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Especialidades;
