import React from "react";
import "./Contato.css";

function Contato() {
  return (
    <div class="contato-wrapper">
      <div className="contato-container">
        <div className="map-div">

          <iframe

            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3969.189115327761!2d-35.21320578906404!3d-5.828940457440034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7b2ff8922411c59%3A0x3f0cd2dc531a5202!2sRVR%20Office!5e0!3m2!1spt-BR!2sbr!4v1719760987045!5m2!1spt-BR!2sbr"
            width="100%"
            height="100%"
            style={{ border: 0, padding: 0, margin: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="contato-content">
          <h2>CONTATO</h2>
          <h3>Fale conosco!</h3>
          <p>
            <i class="uil uil-whatsapp"></i>(84) 99922-3185
          </p>
          <p>
            <i class="uil uil-envelope"></i>contatothalitabarbosa@gmail.com
          </p>
          <p>
            {/* <i class="uil uil-location-point"></i>OTC – Office Tower Center,
            Sala 303 Rua Promotor Manoel Alves Pessoa Neto, 45 Candelária,
            Natal, CEP: 59065-555 */}
          </p>
          <div className="socials">
            <a
              href="https://www.linkedin.com/in/thalitafbarbosa/"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i className="uil uil-linkedin-alt"></i>
            </a>

            <a
              href="https://www.instagram.com/thalitafbarbosa/"
              target="_blank"
              aria-label="site principal do escritório"
              rel="noreferrer"
            >
              <i className="uil uil-instagram"></i>
            </a>

            <a
              href="https://wa.me/5584999223185"
              aria-label="Whatsapp Icon"
              target="_blank"
              rel="noreferrer"
            >
              <i className="uil uil-whatsapp"></i>
            </a>

            <a
              href="mailto:contatothalitabarbosa@gmail.com"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i class="uil uil-envelope"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contato;
