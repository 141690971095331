import React from "react";
import "./Diferenciais.css";

function Diferenciais() {
  return (
    <div className="pontos-fortes-wrapper">
      <div className="pontos-fortes-container">
      
        <div>
          <i className="uil uil-balance-scale"></i>
          <h4>Direito Digital</h4>
          <p>
            Como especialista em Direito Digital, ofereço consultoria jurídica de excelência em todas as áreas, incluindo privacidade, segurança e proteção de dados.
          </p>
        </div>
        <div>
          <i className="uil uil-briefcase"></i>
          <h4>Direito Empresarial</h4>
          <p>
            Forneço assessoria completa em Direito Empresarial, auxiliando meus clientes em questões contratuais e societárias, garantindo a segurança e a eficiência nas operações empresariais.
          </p>
        </div>
        <div>
          <i className="uil uil-users-alt"></i>
          <h4>Direito Trabalhista</h4>
          <p>
            Ofereço assessoria completa em Direito Trabalhista, auxiliando meus clientes em questões relacionadas a relações de trabalho e processos trabalhistas, sempre focada em soluções eficientes e personalizadas.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Diferenciais;
