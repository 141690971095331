
import React, { useState, useEffect } from 'react';
import MenuBars from '../../assets/imgs/menu-bars.svg';
import Menu from './Menu';
import { Link } from 'react-router-dom';
import './Header.css';

import Logo from '../../assets/imgs/tlogo.png';

const Header = ({ handleNavigationBtn, menuItems, socialLinks }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  
  return (
    <div className={`${isScrolled ? 'scrolled' : ''} header-wrapper`}>
      <header className='header-container'>

        <div className='header-img-container'>
              <button onClick={scrollUp}>
                <img
                  className="logo-link header-img"
                  src={Logo}
                  alt="logo imagem"
                ></img>
              </button>
        </div>

        <div className='nav-container'>
          <nav>
            <ul>
              {menuItems.map((item, index) => (
                <li key={index}>
                  <button onClick={() => handleNavigationBtn(item)}>
                    {item.label}
                  </button>
                </li>
              ))}
            </ul>
          </nav>

          <div className='social-links'>
            {socialLinks.map((link, index) => (
              <a key={index} href={link.url} target='_blank' rel='noreferrer'>
                <i className={`${link.icon}`} aria-label={link.label}></i>
              </a>
            ))}
          </div>
        </div>
        <button className='menu-toggle' onClick={toggleSidebar}>
          <img src={MenuBars} alt='Menu Bars' />
        </button>
      </header>
      <Menu
        handleNavigationBtn={handleNavigationBtn}
        menuItems={menuItems}
        socialLinks={socialLinks}
        toggleSidebar={toggleSidebar}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default Header;
