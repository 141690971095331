import React from "react";
import "./Hero.css";

function Hero() {
  return (
    <div class="hero-wrapper">
      <div className="bg-image">
      </div>
      <div class="image-overlay"></div>
      <div className="hero-container">
        <div
          className="hero-content
      "
        >
          <h1 className="nome">THALITA BARBOSA</h1>
          <h1>Advogada Especialista em Direito Digital, Empresarial e Trabalhista.</h1>
          <p>
            Te ajudo a solucionar conflitos na seara do Direito Digital,
            Empresarial e Trabalhista através de uma orientação jurídica sólida e personalizada.
          </p>
          <a
            href="https://wa.me/5584999223185"
            target="_blank"
            rel="noreferrer"
            className='main-btn'>Entre em contato</a>
        </div>
        <div className="hero-img"></div>
      </div>
    </div>
  );
}

export default Hero;
