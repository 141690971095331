import React from 'react';
import './CTAFinal.css';

function Depoimentos() {
  return (
    <div className='depoimentos-container'>
      <div className="depoimentos-content">
        <h2>Assistência imediata</h2>
        <a
          href="https://wa.me/5584999223185"
          target="_blank"
          rel="noreferrer"
          className='main-btn'>Fale conosco agora pelo Whatsapp</a>
      </div>
      <div className='overlay'></div>
    </div>
  );
}

export default Depoimentos;
