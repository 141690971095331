import React from "react";
import Slider from "react-slick";
import "./Palestras.css";
import Json from "./palestras.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Palestras() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow custom-next`}
        style={{ ...style }}
        onClick={onClick}
      >
        <i class="uil uil-angle-right"></i>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow custom-prev`}
        style={{ ...style }}
        onClick={onClick}
      >
        <i class="uil uil-angle-left"></i>
      </div>
    );
  }
  return (
    <div className="palestras-container">
      <h2>Palestras</h2>
      <Slider {...settings} className="cards-container">
        {Json.map((palestra, index) => (
          <div key={index} className="card">
            <div className="card-image">
              <img src={require(`../../assets/imgs/palestras/${palestra.imagem}`)} alt={palestra.titulo} />
            </div>
            <div className="card-content">
              <div>
                <h3 className="card-title">{palestra.titulo}</h3>
                <p className="card-description">{palestra.descricao}</p>
              </div>
              <a href="https://wa.me/5584999223185"
                target="_blank"
                rel="noreferrer" className="card-link">
                Saiba mais
              </a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Palestras;
