const socialLinks = [
    {
        url: 'https://www.linkedin.com/in/thalitafbarbosa/',
        label: 'Linkedin icon',
        icon: 'uil uil-linkedin-alt',
    },
    {
        url: 'https://www.instagram.com/thalitafbarbosa/',
        label: 'Instagram icon',
        icon: 'uil uil-instagram',
    },
    {
        url: 'https://wa.me/5584999223185',
        label: 'Whatsapp Icon',
        icon: 'uil uil-whatsapp',
    }
];

export default socialLinks;

