import React from "react";
import "./Sobre.css";
import Foto from "../../assets/imgs/IMG_6525.jpg";

function Sobre() {
  return (
    <div class="sobre-wrapper">
      <div className="sobre-container">
        <div className="sobre-text">
          <h2>Conte com uma especialista para te guiar</h2>
          <h3>Thalita Barbosa - OAB/RN 22.131</h3>
          <p>
            Thalita Barbosa é formada em direito pela Universidade do Estado do
            Rio Grande do Norte - UERN e advogada inscrita na Ordem dos
            Advogados, sob o número 22.131.
          </p>
          <p>
            Advogada Associada ao Leonardo Dantas e Advogados, apresenta
            experiência na área do direito empresarial e digital, além disso é
            pós-graduanda em Direito Digital pela Escola Paulista de Direito -
            EPD e certificada na área de Propriedade intelectual pelo Instituto
            Nacional de Propriedade Intelectual (INPI) e a World Intellectual
            Property Organization (WIPO).
          </p>
          <a
            href="https://wa.me/5584999223185"
            target="_blank"
            rel="noreferrer"
            className="main-btn"
          >
            Saiba mais
          </a>
        </div>
        <div className="sobre-image">
          <img src={Foto}></img>
        </div>
      </div>
    </div>
  );
}

export default Sobre;
