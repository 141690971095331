// Footer.js
import React from 'react';
import './Footer.css';

import Logo from '../../assets/imgs/tlogo.png';
const scrollUp = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
const Footer = ({ handleNavigationBtn, menuItems, socialLinks }) => {
  return (
    <footer className="footer-background">
      <div className="footer-container">
        <div className="footer-logo">
          <button onClick={scrollUp}>
            <img
              className="logo-link header-img"
              src={Logo}
              alt="logo imagem"
            ></img>
          </button>
          <p>
            Thalita Barbosa - Advogada Especialista em Direito Digital, Empresarial e Trabalhista.
          </p>
        </div>

        <div className="footer-item">
          <h5>NAVEGAÇÃO</h5>
          <div>
            {menuItems.map((item, index) => (
              <li key={index}>
                <button onClick={() => handleNavigationBtn(item)}>
                  {item.label}
                </button>
              </li>
            ))}
          </div>
        </div>

        <div className="footer-item">
          <h5>ÁREAS E ESPECIALIDADES</h5>
          <div className="especialidades">
            <p>Direito Digital</p>
            <p>Direito Empresarial</p>
            <p>Direito Trabalhista</p>
            
          </div>
        </div>

        <div className="footer-item">
          <div>
            <h5>CONTATO</h5>
            <p>Email: contatothalitabarbosa@gmail.com</p>
            <p>Telefone: (84) 99922-3185</p>
          </div>
          <div>
            <h6>ENDEREÇO</h6>
            <p>Av. Sen. Salgado Filho, 2015 - RVR Office</p>
            <p>Lagoa Nova, Natal - RN, 59078-000</p>
          </div>
        </div>
      </div>

      <div className="footer-copy">
        <p>&copy; 2024 Thalita Barbosa. Todos os direitos reservados.</p>
        <div className="footer-socials">
          {socialLinks.map((link, index) => (
            <a key={index} href={link.url} target="_blank" rel="noreferrer">
              <i className={`${link.icon}`} aria-label={link.label}></i>
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;

